import React from 'react';
import { StyledMainSection, StyledPageWrapper } from '../../shared/app-styles';
import {
  StyledHeroSection,
  StyledHeroText,
} from './tutoring-styles';

const Tutoring = () => (
  <StyledPageWrapper>
    <StyledMainSection>
      <StyledHeroSection>
        <StyledHeroText>
          <h1>Tutoring</h1>
          <p>Are you a computer science student and need additional help with your coursework?</p>
          <p>I have some bandwidth and will be making myself available for 1:1 tutoring sessions either over Zoom or in Person to cover
            classwork, questions, career coaching and anything else of interest to those studying computer science.</p>
        </StyledHeroText>
      </StyledHeroSection>
      <StyledHeroSection>
        <StyledHeroText>
          <h2>Rates</h2>
          <p>You will have the opportunity to learn <b>directly</b> from a <b>Senior Software Engineer</b> at a big tech company.</p>
          <p><b>$50 / hr</b></p>
        </StyledHeroText>
      </StyledHeroSection>
      <StyledHeroSection>
        <StyledHeroText>
          <h2>Contact</h2>
          <p>If you are interested in 1:1 tutoring or career coaching sessions, please reach out to me at <b>rego.elton+tutoring@gmail.com</b></p>
        </StyledHeroText>
      </StyledHeroSection>
    </StyledMainSection>
  </StyledPageWrapper>
);

export default Tutoring;
