import styled from 'styled-components';

export const StyledHeroSection = styled.div`
  display: flex;
  flex-wrap: wrap;
`;

export const StyledHeroText = styled.div`
  max-width: 400px;
`
